




















import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "EmployeeReportList",
  data: () => ({
    headers: [
      { text: "Type", value: "name", sortable: false },
      { text: "Sales", value: "value", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      {
        name: "Staff Revenue",
        value: 500000,
      },
      {
        name: "Staff attendance Rate",
        value: "90%",
      },
    ],
  }),
});
