

















































import Vue from "vue";
import ActionButtons from "@/components/ActionButtons.vue";
import SalesReportList from "@/components/reports/SalesReportList.vue";
import EmployeeReportList from "@/components/reports/EmployeeReportList.vue";
import MarketingReportList from "@/components/reports/MarketingReportList.vue";
import FinancialReportList from "@/components/reports/FinancialReportList.vue";
import PaymentReportList from "@/components/reports/PaymentReportList.vue";
import ClientReportList from "@/components/reports/ClientReportList.vue";
import LocationReportList from "@/components/reports/LocationReportList.vue";

export default Vue.extend<any, any, any, any>({
  components: {
    ActionButtons,
    SalesReportList,
    EmployeeReportList,
    MarketingReportList,
    FinancialReportList,
    PaymentReportList,
    ClientReportList,
    LocationReportList,
  },
  name: "Reports",
  data: () => ({
    tab: null,
    items: [
      { icon: "", title: "Sales reports", tab: "sales" },
      { icon: "", title: "Staff reports", tab: "staff" },
      { icon: "", title: "Marketing reports", tab: "marketing" },
      { icon: "", title: "Finance reports", tab: "finance" },
      { icon: "", title: "Payment reports", tab: "payment" },
      { icon: "", title: "Client reports", tab: "client" },
      { icon: "", title: "Location reports", tab: "location" },
    ],
  }),
});
