




















import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "MarketingReportList",
  data: () => ({
    headers: [
      { text: "Type", value: "report_name", sortable: false },
      { text: "Usage", value: "value", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      { report_name: "SMS marketing", value: 500 },
      { report_name: "Email Marketing", value: 300 },
      { report_name: "Agizo Marketing", value: 200 },
      { report_name: "Facebook Marketing", value: 200 },
      { report_name: "Google Marketing", value: 200 },
    ],
  }),
});
