




















import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "PaymentReportList",
  data: () => ({
    headers: [
      { text: "Type", value: "report_name", sortable: false },
      { text: "amount", value: "value", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      { report_name: "All Payments", value: 500 },
      { report_name: "Cash Payments", value: 300 },
      { report_name: "Paypal Payments", value: 200 },
      { report_name: "Mpesa Payments", value: 200 },
      { report_name: "Card Payments", value: 200 },
    ],
  }),
});
