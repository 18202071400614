




















import Vue from "vue";
export default Vue.extend<any, any, any, any>({
  name: "FinancialReportList",
  data: () => ({
    headers: [
      { text: "Type", value: "report_name", sortable: false },
      { text: "actions", value: "actions", sortable: false },
    ],
    reports: [
      { report_name: "Finance Summary", value: 500 },
      { report_name: "Tax Summary", value: 300 },
    ],
  }),
});
