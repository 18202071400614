




























































import Vue from "vue";
import ClientStoreModule from "@/store/modules/client";
import { createNamespacedHelpers } from "vuex";
import { Business, Role } from "@/types";
import moment from "moment-timezone";
const tz = localStorage.getItem("tz") ?? "Africa/Nairobi";

const { mapGetters: CurrentMonthGetters, mapActions: CurrentMonthActions } =
  createNamespacedHelpers("CLIENT_CURRENT_MONTH_COUNT");
const { mapGetters: LastMonthGetters, mapActions: LastMonthActions } =
  createNamespacedHelpers("ClIENT_LAST_MONTH_COUNT");

const { mapGetters: ClientGetters, mapActions: ClientActions } =
  createNamespacedHelpers("CLIENT_COUNT");

export default Vue.extend<any, any, any, any>({
  name: "ClientReportList",
  data() {
    return {
      returnRateDialog: false,
      headers: [
        { text: "Type", value: "report_name", sortable: false },
        { text: "Rate", value: "value", sortable: false },
        { text: "actions", value: "actions", sortable: false },
      ],
      reports: [
        { report_name: "Appointment Rate", value: 0, type: "" },
        { report_name: "Client return Rate", value: 0 },
        { report_name: "New Clients", value: 0, type: "return_rate" },
        { report_name: "Client reviews", value: 0, type: "" },
        { report_name: "Top services", value: 0, type: "" },
      ],
      clientHeaders: [
        { text: "Name", value: "fullName", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Phone", value: "phone", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.fetchALLData();
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    ...CurrentMonthGetters({
      currentMonthCount: "clientCount",
      currentMonthClients: "getNewClients",
    }),
    ...LastMonthGetters({
      lastMonthCount: "clientCount",
    }),
    ...ClientGetters(["clientCount"]),
    newCLientsRate(): string {
      const growthRate =
        ((this.currentMonthCount - this.lastMonthCount) / this.lastMonthCount) *
        100;
      if (growthRate === Infinity) return `Not enough data`;
      return `${Math.trunc(growthRate) || 0}%`;
    },
  },
  methods: {
    ...CurrentMonthActions({ fetchCurrentClientCount: "fetchClientCount" }),
    ...LastMonthActions({ fetchLastClientCount: "fetchClientCount" }),
    ...ClientActions(["fetchClientCount"]),
    fetchALLData() {
      if (this.role) {
        const business = (this.role.business as Business)._id;
        this.fetchClientCount(`?businessId=${business}`);

        let startDate = moment().startOf("week").toISOString();
        let endDate = moment().toISOString();

        endDate = moment().toISOString();
        startDate = moment(endDate).subtract(1, "month").toISOString();
        this.fetchCurrentClientCount(
          `?businessId=${business}&endDate=${endDate}&startDate=${startDate}`
        );

        endDate = moment().subtract(1, "month").toISOString();
        startDate = moment(endDate).subtract(1, "month").toISOString();
        this.fetchLastClientCount(
          `?businessId=${business}&endDate=${endDate}&startDate=${startDate}`
        );
      }
    },
    openDialog(type: string) {
      if (type === "return_rate") this.returnRateDialog = true;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("ClIENT_LAST_MONTH_COUNT")) {
      this.$store.registerModule("ClIENT_LAST_MONTH_COUNT", ClientStoreModule);
    }

    if (!this.$store.hasModule("CLIENT_CURRENT_MONTH_COUNT")) {
      this.$store.registerModule(
        "CLIENT_CURRENT_MONTH_COUNT",
        ClientStoreModule
      );
    }

    if (!this.$store.hasModule("CLIENT_COUNT")) {
      this.$store.registerModule("CLIENT_COUNT", ClientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("ClIENT_LAST_MONTH_COUNT");
    this.$store.unregisterModule("CLIENT_CURRENT_MONTH_COUNT");
    this.$store.unregisterModule("CLIENT_COUNT");
  },
});
